const APPLICATION_PATH = 
{
    home: {
        'welcome.introduction':
        {
            path: '/',
            component: () => import('@/Pages/Index/TheWelcome.vue'),
        },
        // 'welcome.ecosystem':
        // {
        //     path: '/welcome/ecosystem',
        //     component: () => import('@/Pages/Index/TheWelcome.vue'),

        // },
        // 'welcome.showcase':
        // {
        //     path: '/welcome/showcase',
        //     component: () => import('@/Pages/Index/TheWelcome.vue'),    
        // },
        'skills.programming-languages':
        {
            path: '/skills/programming-languages',
            component: () => import('@/Pages/Skills/SkillProgrammingLanguages.vue'),
        },
        'skills.framework': {
            path: '/skills/framework',
            component: () => import('@/Pages/Skills/SkillFramework.vue')
        },
        'skills.tools': {
            path: '/skills/tools',
            component: () => import('@/Pages/Skills/SkillTools.vue'),
        },
        'works.welcome':
        {
            path: '/works',
            component: () => import('@/Pages/Work/TheWork.vue'),
        },
        'services.introduction':
        {
            path: '/services',
            component: () => import('@/Pages/Services/ServiceIntroduction.vue'),
        },
        'services.development':
        {
            path: '/services/development',
            component: () => import('@/Pages/Services/ServiceDevelopment.vue'),
        },
        'services.design': 
        {
            path: '/services/design',
            component: () => import('@/Pages/Services/ServiceDesign.vue'),
        },
        'services.support':
        {
            path: '/services/support',
            component: () => import('@/Pages/Services/ServiceSupport.vue'),
        },

        'bio.introduction':
        {
            path: '/about/bio',
            component: () => import('@/Pages/About/AboutBio.vue'),
        },
        'gallery.index':
        {
            path: '/about/galleries',
            component: () => import('@/Pages/About/AboutGallery.vue'),
        }
    },
    library:
    {
        'article.magazine':
        {
            path: '/articles',
            component: () => import('@/Pages/Article/Interfaces/ArticleMagazine.vue'),
        },
        'article.timeline':
        {
            path: '/articles/timeline',
            component: () => import('@/Pages/Article/Interfaces/ArticleTimeline.vue'),
        },
        'article.show':
        {
            path: '/articles/:article',
            component: () => import('@/Pages/Article/Interfaces/ArticleShow.vue'),
        },
        'news.index':
        {
            path: '/news',
            component: () => import('@/Pages/News/Engine/TheNews.vue'),
        },
        'tutorial.index':
        {
            path: '/tutorials',
            component: () => import('@/Pages/Tutorials/Engine/TheTutorials.vue'),
        }
    },
    project:
    {
        'application.index':
        {
            path: '/project/application',
            component: () => import('@/Pages/Project/TheProject.vue'),
        },
        'template.index':
        {
            path: '/project/template',
            component: () => import('@/Pages/Project/Template/TheTemplate.vue'),
        },
        'games.index':
        {
            path: '/project/games',
            component: () => import('@/Pages/Project/Games/TheGames.vue'),
        },
        '3d-object.index':
        {
            path: '/project/3d-object',
            component: () => import('@/Pages/Project/Dimension/The3DObject.vue'),
        }
    },
    about:
    {
        
    },
    contact:
    {
        'get-in-touch.welcome':
        {
            path: '/contacts/get-in-touch',
            component: () => import('@/Pages/Contact/TheContact.vue'),
        },
        'link.index':
        {
            path: '/contacts/link',
            component: () => import('@/Pages/Link/TheLink.vue'),
        }
    }
};

export { APPLICATION_PATH }