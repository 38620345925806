<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

//
import HomeGroup from "@/Views/Layouts/Menu/Group/HomeGroup.vue";
import ProjectGroup from "@/Views/Layouts/Menu/Group/ProjectGroup.vue";
import LibraryGroup from "@/Views/Layouts/Menu/Group/LibraryGroup.vue";
import AboutGroup from "@/Views/Layouts/Menu/Group/AboutGroup.vue";
import ContactGroup from "@/Views/Layouts/Menu/Group/ContactGroup.vue";
// Layout Composables
import { useActiveMainMenu } from '@/Layouts/Composables/useActiveMainMenu';
import type { ComputedRef } from 'vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Active main menu.
 * 
 * @type { ComputedRef<boolean> }
 */
const activeMainMenu: ComputedRef<string> = useActiveMainMenu;

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <HomeGroup v-if="activeMainMenu === 'home'" location="aside" />
    <ProjectGroup v-if="activeMainMenu === 'project'" location="aside" />
    <LibraryGroup v-if="activeMainMenu === 'library'" location="aside" />
    <AboutGroup v-if="activeMainMenu === 'about'" location="aside" />
    <ContactGroup v-if="activeMainMenu === 'contact'" location="aside" />
</template>