import { createRouter, createWebHistory, type Router } from "vue-router";
import { setupRoutes } from "@/Routes/RouteUtils";
import { APPLICATION_PATH } from "@/Data/Path/ApplicationPath";

/**
 * Set route list.
 * 
 * @type { ReadonlyArray<any> }
 */
const routes: ReadonlyArray<any> = setupRoutes(APPLICATION_PATH);

/**
 * Setup router.
 * 
 * @type { Router }
 */
const router: Router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    next();
})

export default router;