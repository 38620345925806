<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { defineAsyncComponent } from "vue";
// Route Utils
import { setupRouteList } from "@/Routes/RouteUtils";
import { APPLICATION_PATH } from "@/Data/Path/ApplicationPath";
// Layout
import TheLayout from "@/Layouts/TheLayout.vue";
// Views Layouts
import HeaderApplicationNavigation from "@/Views/Layouts/Components/Header/HeaderApplicationNavigation.vue";
import FloatMobileApplicationPageMenu from "@/Views/Layouts/Components/Float/FloatMobileApplicationPageMenu.vue";
import AsideApplicationMainMenuMiddle from "@/Views/Layouts/Components/Aside/AsideApplicationMainMenuMiddle.vue";
import AsideApplicationMainMenuBottom from "@/Views/Layouts/Components/Aside/AsideApplicationMainMenuBottom.vue";
import AsideApplicationPageMenu from "@/Views/Layouts/Components/Aside/AsideApplicationPageMenu.vue";

/*
** !SECTION
|----------------------------------------
** SECTION: Async Component
*/

const FloatApplicationMainMenu = defineAsyncComponent({
    loader: () => import('@/Views/Layouts/Components/Float/FloatApplicationMainMenu.vue'),
    delay: 0,
});
const FloatApplicationPageMenu = defineAsyncComponent({
    loader: () => import('@/Views/Layouts/Components/Float/FloatApplicationPageMenu.vue'),
    delay: 0,
});

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Route List.
 * 
 * @type { Record<any, any> }
 */
const routeList: Record<any, any> = setupRouteList(APPLICATION_PATH);

/**
 * PART: Index Route. 
 * 
 * @type { string }
 */
const INDEX_ROUTE: string = '/';

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <TheLayout :route-list="routeList" :index-route="INDEX_ROUTE">
        <template #header-navigation>
            <HeaderApplicationNavigation />
        </template>
        <template #header-navigation-mobile>
            <HeaderApplicationNavigation />
        </template>

        <template #float-main-menu>
            <FloatApplicationMainMenu />
        </template>
        <template #float-page-menu>
            <FloatApplicationPageMenu />
        </template>
        <template #float-navigation>
            <FloatMobileApplicationPageMenu />
        </template>

        <template #aside-menu-middle>
            <AsideApplicationMainMenuMiddle />
        </template>
        <template #aside-menu-bottom>
            <AsideApplicationMainMenuBottom />
        </template>
        <template #aside-page-menu>
            <AsideApplicationPageMenu />
        </template>
    </TheLayout>    
</template>