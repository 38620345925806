import "@/Bootstrap/Bootstrap";
import "@/Assets/scss/style/application.scss";

import TheApplication from "@/Views/Layouts/TheApplication.vue";
import router from "@/Routes/ApplicationRoute";
import { createApplication } from "@/Modules/App";

createApplication(TheApplication, router).then(({ app, router }) => {
    app.mount('#geardevs');
});

// const vue: App<Element> = createApp(TheApplication);

// vue.use(router);
// vue.use(createPinia());
// vue.use(createHead());
// vue.mount("#geardevs-a1");

// vue.config.errorHandler = (error: unknown, instance, info: string) =>
// {
//     console.error(error, info);
// };

