<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Vue
import { defineAsyncComponent } from 'vue';
// Layout Composables
import { useActivePageMenu } from '@/Layouts/Composables/useActivePageMenu';
// View Navigation
import WelcomeNavigation from '@/Views/Layouts/Menu/Navigation/WelcomeNavigation.vue';

/*
** !SECTION
|----------------------------------------
** SECTION: Async Components
*/

const ArticleInterfaceNavigation = defineAsyncComponent(() => 
    import('@/Views/Layouts/Menu/Navigation/ArticleInterfaceNavigation.vue')
);

const ServicesNavigation = defineAsyncComponent(() => 
    import('@/Views/Layouts/Menu/Navigation/ServicesNavigation.vue')
);

const SkillsNavigation = defineAsyncComponent(() =>
    import('@/Views/Layouts/Menu/Navigation/SkillsNavigation.vue'),
);

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <WelcomeNavigation v-if="useActivePageMenu === 'welcome'" />
    <ArticleInterfaceNavigation v-if="useActivePageMenu === 'article'" />
    <ServicesNavigation v-if="useActivePageMenu === 'services'" />
    <SkillsNavigation v-if="useActivePageMenu === 'skills'" />
</template>