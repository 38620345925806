<script setup lang="ts">
/*
|----------------------------------------
** SECTION: Import
*/

// Layout Stores
import { useFloatMobileNavigationStore } from "@/Layouts/Stores/useFloatStore";
import type { FloatMobileNavigationStore } from "@/Types/stores";
//
import HomeMobileGroup from "@/Views/Layouts/Menu/Group/HomeMobileGroup.vue";
import { storeToRefs } from "pinia";

/*
** !SECTION
|----------------------------------------
** SECTION: Composition
*/

/**
 * PART: Initialize useFloatMobileNavigationStore.
 * 
 * @type { FloatMobileNavigationStore }
 */
const floatMobileNavigationStore: FloatMobileNavigationStore = useFloatMobileNavigationStore();
const { name, show } = storeToRefs(floatMobileNavigationStore);

/*
** !SECTION
|----------------------------------------
*/
</script>
<template>
    <HomeMobileGroup v-if="name === 'main-menu' && show === true" />
    <div v-if="name === 'workspace-menu' && show === true">
        Workspace
    </div>
</template>